@import 'imports';

.continue-header-title {
  @extend .semi-bold;

  font-size: 34px;
  line-height: 51px;
}

.continue-header-subtitle {
  @extend .medium;

  font-size: 16px;
  @include xxxs {
    font-size: 14px;
  }
  line-height: 22px;
}
