@import 'imports';
$checkbox-container: 24px; //by changing it, it also changes the checkmark size
$checkmark-height: $checkbox-container * 0.48;
$checkmark-width: $checkbox-container * 0.20;
$checkbox-width-line: 2px;
$border: 3px;
$border-color: $purple;
$border-radius: 4px;

.checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.checkbox-label input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.checkbox-label .checkbox-custom {
  border: $border solid $purple;
  border-radius: $border-radius;
  color: $purple;
  height: $checkbox-container;
  transition: all $transition-duration ease-out;
  width: $checkbox-container;
}

.checkbox-label-error .checkbox-custom-error {
  border: $border solid $error;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: $purple;
  border: $border solid $purple;
  color: $white;
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

.checkbox-label .checkbox-custom::after {
  content: '';
  position: fixed;
  transition: all $transition-duration ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  border: solid;
  border-width: 0 $checkbox-width-line $checkbox-width-line 0;
  height: $checkmark-height;
  left: $checkmark-width * 1.3;
  opacity: 1;
  top: $checkmark-height * 0.1;
  transform: rotate(45deg) scale(1);
  width: $checkmark-width;
}

.container {
  @extend .row,
  .middle;
}

.checkbox-text {
  @extend .oxford-blue;

  font-size: 12px;
  font-style: italic;
  line-height: 20px;
}
