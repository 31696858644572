@import 'variables/colors';

@import 'variables/breakpoints';

.container {
  justify-content: space-around;
  min-height: 120px;
  width: 100%;
}

.background {
  background-color: $hawkes-blue;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
  height: 125px;
  @include xs {
    width: 75%;
  }
  padding: 10px 0;
  position: absolute;
  width: 300px;
  z-index: -1;
}

.product {
  width: 200px;
  z-index: -1;
}

.picture {
  display: table;
  margin: auto;
  width: 100%;
}
