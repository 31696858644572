@import 'imports';
$logo-size: 18px;

.container {
  @extend .row,
  .middle,
  .center,
  .kayak-width;

  min-height: fit-content;
  padding-top: 10px;
  text-align: center;
}

.contact {
  @extend .row,
  .full-width,
  .center,
  .m-bottom-2;
}

.logo-container {
  height: $logo-size;
  margin-right: 3px;
  width: $logo-size;
}

.logo {
  height: $logo-size;
  width: $logo-size;
}

.message-info {
  @extend .row,
  .full-width,
  .center,
  .middle,
  .m-bottom-4;

  color: $marshland;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
}

.message-link {
  @extend .message-info,
  .middle;

  color: $info-blue;
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  text-decoration: underline;
}

.after-text {
  display: inline-block;
  margin: 0 5px;
}
