@import 'imports';
$background-border-radius: 300px;

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;

  background-color: $purple;
}

.tick {
  @include height-xxs {
    top: 10%;
  }
  position: absolute;
  top: 20%;
}

.tick-picture {
  @extend .kayak-width;

  @include height-xxs {
    width: 65%;
  }
}

.background {
  @extend .column,
  .full-width,
  .center,
  .middle,
  .semi-bold,
  .teal-blue;

  background-color: $white;
  border-bottom-left-radius: $background-border-radius;
  border-bottom-right-radius: $background-border-radius;
  font-size: 34px;
  height: 26%;
  padding-bottom: 40px;
  text-align: center;
}

.info {
  @extend .white;

  font-size: 14px;
  line-height: 20px;
  text-align: center;
}

.email {
  @extend .semi-bold;
}

.buttons {
  @extend .column,
  .center,
  .full-width;

  padding: 30px;
}

.email-button {
  width: fit-content;
}

.alert-container {
  color: $white;
  text-align: center;
  margin-inline: 15px;
  padding: 10px;
  border: 2px solid $dark-yellow;
  border-radius: 15px;

  b {
    color: $dark-yellow;
  }

  .logo, .new-logo {
    height: 30px;
    width: 125px;
  }

  .new-logo {
    padding: 4px;
    width: 100px;
  }

  .center-group {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .arrow {
    margin-left: 15px;
    margin-right: 10px;
  }

  .divider {
    @extend .m-bottom-2,
    .m-top-2;

    width: 100%;
    max-width: 230px;
    height: 2px;
    background-color: $white;
  }

  .message-link {
    @extend .semi-bold;

    color: $dark-yellow;
    text-decoration: underline;
    margin-left: 3px;
  }
}
