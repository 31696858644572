@import 'imports';
$background-border-radius: 300px;

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;

  background-color: $teal-blue;
}

.background {
  @extend .column,
  .full-width,
  .center,
  .middle,
  .teal-blue;

  background-color: $white;
  border-bottom-left-radius: $background-border-radius;
  border-bottom-right-radius: $background-border-radius;
  height: 55%;
  padding-bottom: 40px;
  text-align: center;
}

.maintenance-info-container {
  height: 200px;
}

.maintenance-title {
  @extend .bold;

  font-size: 24px;
}

.maintenance-subtitle {
  @extend .regular;

  font-size: 14px;
}

.maintenance-button {
  @extend .kayak-width,
  .m-bottom-8;
}

.picture {
  @include height-xxs {
    top: 30%;
  }
  @include height-xs {
    top: 33%;
  }
  position: absolute;
  top: 35%;
}
