@import 'imports';

.modal {
  height: fit-content;
  max-width: 320px;
  padding: 0;
}

.container {
  @extend .column,
  .center;

  padding: 30px;
}

.state-title {
  @extend .teal-blue,
  .medium;

  font-size: 16px;
  line-height: 22px;
  margin: 15px 0;
}

.state-text {
  @extend .oxford-blue;

  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
  text-align: center;
}
