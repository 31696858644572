@import './variables/breakpoints';

@import './variables/colors';

// Font weights

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

// Format text

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-justify {
  text-align: justify;
}

.line-through {
  text-decoration: line-through;
}

.large-title {
  font-size: 50px;
  @include md {
    font-size: 40px;
  }
  @include sm {
    font-size: 30px;
  }
  line-height: 60px;
  text-align: center;
}

.title {
  font-size: 36px;
  @include md {
    font-size: 30px;
  }
  @include sm {
    font-size: 24px;
  }
  line-height: 40px;
}

.subtitle {
  font-size: 30px;
  @include md {
    font-size: 24px;
  }
  @include sm {
    font-size: 18px;
  }
  line-height: 35px;
}

.large-text {
  font-size: 24px;
  @include md {
    font-size: 20px;
  }
  line-height: 30px;
}

.x-large-text {
  font-size: 28px;
  @include md {
    font-size: 24px;
  }
  line-height: 42px;
}

.xx-large-text {
  font-size: 38px;
  @include md {
    font-size: 34px;
  }
  line-height: 40px;
}

.xx-small-text {
  font-size: 10px;
  line-height: 18px;
}

.x-small-text {
  font-size: 12px;
  @include xxxs {
    font-size: 10px;
  }
  line-height: 28px;
}

.small-text {
  font-size: 14px;
  @include xxxs {
    font-size: 12px;
  }
  line-height: 28px;
}

.text {
  font-size: 20px;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
  line-height: 24px;
}

.small-title {
  font-size: 13px;
  @include xxxs {
    font-size: 11px;
  }
  line-height: 20px;
}

.medium-title {
  font-size: 16px;
  line-height: 22px;
}

.small-label {
  font-size: 12px;
  line-height: 18px;
}

.label {
  font-size: 14px;
  @include xxxs {
    font-size: 12px;
  }
  line-height: 16px;
}

.underline {
  text-decoration: underline;
}

.error {
  color: $valencia;
  font-size: 24px;
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
  padding-top: 5px;
}

.special-text-guarantor {
  @extend .bold;

  color: $purple;
}

// Text colors

.white {
  color: $white;
}

.oxford-blue {
  color: $oxford-blue;
}

.royal-blue {
  color: $royal-blue;
}

.cosmic-cobalt {
  color: $cosmic-cobalt;
}

.teal-blue {
  color: $teal-blue;
}

.grizzle-gray {
  color: $grizzle-gray;
}

.teal-blue {
  color: $teal-blue;
}

.charcoal {
  color: $charcoal;
}

.info-blue {
  color: $info-blue;
}
