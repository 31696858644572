@import 'variables/breakpoints';

.container {
  @include xs {
    margin-left: 30px;
  }
  @include xxxs {
    margin-left: 20px;
  }
  margin-left: 50px;
}
