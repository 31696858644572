@import 'src/scss/variables/colors';

@import 'src/scss/variables/breakpoints';

@import 'src/scss/animations';

.modal-container {
  @extend %appear;

  background-color: $white;
  border-radius: $default-border-radius;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  height: 100%;
  margin: 0 20px;
  max-width: 400px;
  outline: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.modal-overlay {
  align-items: center;
  background-color: rgba($white, 0.80);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $modal-z-index;
}

.body {
  overflow: hidden;
}

.icon-close {
  font-size: 20px;

  &::before {
    color: $royal-blue;
  }
}

.close-button {
  height: 20px;
  position: absolute;
  right: 13px;
  top: 10px;
  width: 20px;
  z-index: $tooltip-z-index;
}

@media screen and (max-width: $breakpoint-sm) {
  $modal-margin: 10px;

  .modal-container {
    margin: $modal-margin;
    max-height: 90%;
    overflow: auto;
    width: calc(100% - #{$modal-margin} * 2);
  }
}
