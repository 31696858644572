@import 'imports';

.container {
  @extend .column,
  .center,
  .middle,
  .full-width,
  .full-height;

  margin: 0 25px;
}

.button {
  padding: 0 25px;
  width: fit-content;
}

.input {
  @extend .full-width;

  height: 75px;

  @media (max-width: 365px) {
    height: 90px;
  }
}

.input-with-hided-message {
  height: 105px;
}

.message {
  @extend .regular,
  .m-bottom-3;

  color: $marshland;
  font-size: 12px;
  height: 15px;
}
