@import 'imports';

.container {
  @extend .full-height,
  .full-width,
  .column,
  .center,
  .space-between;

  margin-bottom: 20px;
}

.inner-container {
  @extend .full-width,
  .column,
  .center;
}

.sales-container {
  @extend .inner-container,
  .m-top-2,
  .m-bottom-5;

  width: 65%;
}

.title-container {
  @extend .inner-container,
  .m-top-2;
}

.buttons-container {
  @extend .inner-container,
  .m-bottom-2;
}

.info-container {
  @extend .full-height,
  .full-width,
  .column,
  .center,
  .space-evenly;
}

.info-button {
  @extend .row,
  .center,
  .middle,
  .m-bottom-5;

  fill: $info-blue;
  font-size: 12px;

  & span {
    @extend .m-right-1;
  }
}
