@import 'imports';
$border-radius: 100px;

.stepper-bar {
  top: 58px;
}

.logo-container {
  @extend .column,
  .center,
  .middle;

  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  min-height: fit-content;
  width: 35%;
}

.logo {
  margin-bottom: 3px;
}

.logo-without-stepper {
  @extend .m-bottom-3;
}
