@import 'imports';

.continue-with-guarantor-info {
  @extend .transition-subtitle;

  padding: 15px 50px 5px;
}

.question-button {
  height: 20px;
  margin-bottom: 15px;
}
