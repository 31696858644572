@import 'imports';

.container {
  @extend .column,
  .full-width,
  .full-height,
  .center,
  .middle;

  color: $teal-blue;
}

.loading-title {
  @extend .bold;

  font-size: 20px;
  line-height: 27px;
  margin: 10px;
}

.loading-subtitle {
  @extend .loading-title,
  .regular;

  font-size: 18px;
  margin-top: 0;
  text-align: center;
}

.animation-container {
  @extend .half-width;
}
