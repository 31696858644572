@import 'imports';

.container {
  @extend .column,
  .full-width,
  .full-height,
  .center,
  .space-between;

  color: $teal-blue;
  padding: 40px 0;
  text-align: center;
}

.continue-title {
  font-size: 18px;
  line-height: 27px;
}
