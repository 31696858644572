@import 'imports';
$edit-size: 20px;

.range-slider {
  @extend .row,
  .center,
  .kayak-width;

  min-height: fit-content;
}

.card-container {
  @extend .m-bottom-4,
  .kayak-width;

  height: fit-content;
}

.container {
  @extend .column,
  .full-width,
  .center,
  .m-bottom-3;
}

.value-label {
  @extend .row,
  .small-label,
  .bold,
  .oxford-blue,
  .text-uppercase,
  .m-bottom-1;

  min-height: fit-content;
}

.value-container {
  @extend .row,
  .center,
  .middle;
}

.value-container-consumer {
  @extend .m-bottom-3;
}

.value-container-pointer {
  cursor: pointer;
}

.price-sign {
  @extend .medium,
  .cosmic-cobalt,
  .m-right-1,
  .large-text;
}

.price-value {
  @extend .semi-bold,
  .royal-blue,
  .xx-large-text;
}

.edit-button {
  fill: $info-blue;
  height: $edit-size;
  margin-bottom: 3px;
  margin-left: 7px;
  width: $edit-size;
}

.price-column {
  @extend .column,
  .center,
  .middle;
}
