@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;
}

.inner-container {
  @extend .column,
  .full-width,
  .center;

  min-height: fit-content;
}

.confirmation-card {
  margin-bottom: 20px;
}

.icon-confirmation {
  margin: 10px 0;
}

.more-info-button {
  @extend .regular;

  color: $info-blue;
  font-size: 14px;
  margin-bottom: 15px;
}
