@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;

  padding-bottom: 10px;
}

.transition-button {
  @extend .kayak-width,
  .m-bottom-2;
}

.transition-screen-title {
  @extend .transition-header-title;

  font-size: 20px;
}

.buttons-container {
  @extend .full-width,
  .column,
  .center;
}

.transition-header {
  padding-inline: 20px;
}