@import 'imports';

.container {
  width: 90px;
}

.icon-container {
  background-color: $white;
  border-radius: 100px;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 40px;
}

.icon {
  height: 18px;
  width: 18px;
}

.label-completed {
  color: $cosmic-cobalt;
}

.label-selected {
  color: $purple;
}

.label-not-selected {
  color: $grizzle-gray;
}

.icon-not-selected {
  fill: $grizzle-gray;
}

.icon-selected {
  fill: $purple;
}

.icon-completed {
  fill: $white;
}

.icon-container-completed {
  background-color: $cosmic-cobalt;
  border-color: $cosmic-cobalt;
}

.icon-container-selected {
  border-color: $purple;
}

.icon-container-not-selected {
  border-color: $white;
}

.step-text {
  @extend .row,
  .center;

  font-size: 10px;
  line-height: 18px;
  width: 100px;
}
