@import 'imports';

.share-link-card {
  @extend .full-width-card,
  .full-width-card-error,
  .row,
  .center,
  .middle;

  padding: 15px 0;
}

.container {
  @extend .column,
  .kayak-width;
}

.info-container {
  @extend .row,
  .full-width,
  .medium,
  .m-bottom-1;

  color: $error;
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
}

.guarantor-title {
  @extend .m-right-1;
}

.share-link-buttons {
  @extend .column,
  .center,
  .full-width;

  margin: 10px 0;
}
