@import 'imports';

.container {
  @extend .column,
  .full-width,
  .full-height,
  .center,
  .space-around;

  padding: 20px 0;
}

.continue-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.continue-button {
  @extend .kayak-width;
}

.message-info {
  color: $marshland;
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-top: 20px;
  padding-inline: 20px;
}

.message-link {
  color: $info-blue;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}