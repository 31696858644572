@import 'imports';

.container {
  justify-content: space-around;
  min-height: 100px;
  width: 100%;
}

.bar {
  background: $ghost-white;
  box-shadow: inset 0 4px 4px rgba(55, 40, 142, 0.25);
  height: 8px;
  overflow: hidden;
  position: absolute;
  top: 36px;
  z-index: -1;
}

$widths: (
  no: 425px,
  short: 250px,
  medium: 150px,
  long: 100px
);

@each $widthName, $width in $widths {
  .#{$widthName}-bar {
    max-width: calc(425px - #{$width});
    width: calc(100% - #{$width});
  }
}
