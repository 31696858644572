@import 'imports';

.error-container {
  @extend .column,
  .full-width,
  .center;

  color: $error;
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0 15px;
  text-align: center;
}

.main-error-title {
  @extend .regular;
}

.secondary-error-title {
  @extend .bold;
}
