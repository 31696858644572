@import 'imports';

.container {
  height: 240px;
  margin: 10px 0;
  overflow-y: scroll;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
  display: none;
}

.container-with-error {
  height: 180px;
}
