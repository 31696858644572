
@import 'imports';
$dot-size: 7px;
$dot-size-xxs: 6px;

.item-container {
  @extend .row,
  .full-width;

  color: $grizzle-gray;
  font-size: 14px;
  @include xxs {
    font-size: 12px;
    line-height: 18px;
  }
  line-height: 21px;
  margin: 5px 0;
}

.dot {
  background-color: $purple;
  border-radius: 100px;
  height: $dot-size;
  @include xxs {
    height: $dot-size-xxs;
    margin: $dot-size-xxs;
    min-width: $dot-size-xxs;
  }
  margin: $dot-size;
  min-width: $dot-size;
}

.text-container {
  max-width: 200px;
}

.link {
  color: $info-blue;
  cursor: pointer;
  display: inline-block;
  text-decoration: underline;
}

.link-container {
  @extend .row;

  display: inline-block;
}
