@import 'imports';

.file-input {
  display: none;
}

.upload-files-button {
  @extend .medium,
  .row,
  .center,
  .middle;

  background-color: $cosmic-cobalt;
  border-radius: 20px;
  color: $white;
  font-style: italic;
}

.upload-files-button-text {
  margin-right: 10px;
}
