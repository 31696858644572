@import 'imports';

.container {
  @extend .full-width-card,
  .column,
  .center;

  margin: 10px 0;
  min-height: 100px;
  padding: 5px 0 0;
}

.documentation-card-title-container {
  @extend .row,
  .kayak-width,
  .middle;

  padding: 10px 0;
}

.more-info-container {
  @extend .column,
  .full-height,
  .center;
}

.documentation-card-title {
  @extend .medium,
  .teal-blue,
  .m-right-1;

  font-size: 16px;
  line-height: 22px;
}

.documentation-card-optional {
  @extend .documentation-card-title;

  color: $medium-purple;
}

.checkbox-container {
  @extend .row,
  .center,
  .full-width;

  padding: 15px 0;
}
