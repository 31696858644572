@import 'imports';

.form-container {
  @extend .column,
  .center,
  .full-width;
}

.form-field {
  @extend .column,
  .kayak-width;
}

.employment-situation-field {
  @extend .form-field,
  .m-bottom-3;
}

.section-labor-title {
  @extend .row,
  .top,
  .full-width,
  .medium-title,
  .medium ;

  margin-left: 2px;
  color: #263238;
}
