@import 'imports';

.container {
  @extend .column,
  .full-width,
  .full-height,
  .center,
  .middle;
}

.form-container {
  padding: 20px;
}
