$transparent: transparent;
$white: #FFF;
$black: #000;
$black-tooltip: #222;
$blue: #06F;
$azure: #35599F;
$valencia: #D34836;
$silver: #C5C5C5;
$gray-default: #808080;
$light-gray: #CCC;
$whisper: #EEE;
$navbar-color: #F9F9F9;
$dove-gray: #C2D1E2;
$hawkes-blue: #D9E2F4;
$clear-hawkes-blue: #D9E2F487;
$oxford-blue: #263238;
$royal-blue: #7F46EA;
$teal-blue: #051B5F;
$cosmic-cobalt: #37288E;
$grizzle-gray: #626262;
$ghost-white: #F1F1F7;
$dark-gray: #A3A3A3;
$error: #DD2122;
$dim-gray: #737373;
$charcoal: #484848;
$purple: #733FE7;
$info-blue: #006EF0;
$alice-blue: #F3F8FD;
$light-yellow: #FFC108;
$yellow: #EDCF4E;
$dark-yellow: #FFC319;
$selago: #F1F7FE;
$medium-purple: #8F65EC;
$marshland: #050505;
$moon-raker: #F0ECF8;
$cosmos: #FFEBEB;
$light-purple: #F1EBFC;
$light-black: #00000040;
$light-royal-blue: #733FE730;
$light-red: #FFD9D9;
$alizarin-crimson: #DD21227D;
$concrete: #F2F2F2;
$silver-chalice: #A3A3A380;
$clear-portage: #AB8CF133;
$alto: #D3D3D3;
$sandwisp: #FAF3D6;
