@import 'imports';

.modal {
  height: fit-content;
  max-width: 425px;
  padding: 0;
}

.container {
  @extend .column,
  .full-height,
  .space-between;

  padding: 30px 20px 5px;
}

.content-container {
  margin-bottom: 10px;
}

.upload-button-container {
  padding-bottom: 20px;
}

.uploader-title {
  @extend .teal-blue,
  .bold;

  font-size: 16px;
  line-height: 24px;
}

.uploader-text {
  @extend .oxford-blue;

  font-size: 14px;
  line-height: 20px;
  padding: 15px 0;
}

.uploader-text-semi-bold {
  @extend .semi-bold;

  display: inline;
}

.error {
  @extend .regular;

  color: $error;
  font-size: 14px;
  @include xxxs {
    font-size: 12px;
  }
  margin-bottom: 15px;
  text-align: center;
}
