@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .m-bottom-4;

  min-height: 150px;
}

.guarantor-container {
  min-height: auto;
}

.guarantor-header {
  @extend .row,
  .m-bottom-2,
  .kayak-width;
}

.guarantor-title {
  @extend .m-right-1;
}
