@import 'src/scss/variables/colors';

.slider {
  -webkit-appearance: none;
  background: $ghost-white;
  border-radius: 10px;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  height: 15px;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 100%;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: $cosmic-cobalt;
  border-radius: 100px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.slider::-moz-range-thumb {
  background: $cosmic-cobalt;
  border-radius: 100px;
  cursor: pointer;
  height: 30px;
  width: 30px;
}
