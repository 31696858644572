@import 'imports';

.file {
  @extend .bold,
  .oxford-blue,
  .row,
  .space-between,
  .middle;

  border-bottom: 0.5px solid $silver-chalice;
  line-height: 20px;
  margin: 0 5px;
  padding: 20px 0 10px;
}

.file-info-container {
  @extend .column;

  width: 90%;
}

.file-name-container {
  @extend .row,
  .middle;
}

.file-name {
  @extend .text-pre-wrap;

  font-size: 14px;
}

.file-size {
  font-size: 10px;
}

.upload-icon {
  width: 6%;
}

.trash-can {
  @extend .upload-icon;

  cursor: pointer;
}

.error-icon {
  @extend .upload-icon,
  .m-left-1;
}
