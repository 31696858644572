@import 'imports';

.footer-container {
  @extend .column,
  .full-width,
  .center,
  .p-bottom-3;
}

.status-message {
  @extend .charcoal,
  .medium;

  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.store-buttons {
  @extend .row,
  .full-width,
  .center,
  .middle;

  min-height: 50px;
}

.store-button {
  width: 150px;
}

.google-play-button {
  @extend .store-button;

  height: 60px;
  margin-left: 5px;
}

.app-store-button {
  @extend .store-button;

  height: 40px;
}
