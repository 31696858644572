@import 'imports';

.container {
  @extend .row,
  .full-width,
  .center,
  .middle,
  .m-top-4;

  min-height: fit-content;
}

.guarantor-container {
  min-height: auto;
}

.form-container {
  @extend .column,
  .center,
  .full-height,
  .full-width,
  .space-between;
}

.fields-container {
  @extend .kayak-width;

  margin-bottom: 10px;
}

.buttons-container {
  @extend .kayak-width;

  margin-bottom: 10px;
}

.contacts-container {
  @extend .kayak-width;

  margin-bottom: 10px;
}
