@import 'imports';

.container {
  @extend .column,
  .full-width,
  .full-height,
  .center,
  .space-between;

  background-color: $purple;
  color: $white;
  padding: 50px 0;
  text-align: center;
}

.credit-ending-title {
  @extend .regular,
  .m-bottom-2;

  font-size: 20px;
  line-height: 27px;
}

.credit-ending-info {
  @extend .regular,
  .m-top-2,
  .m-bottom-2;

  font-size: 16px;
  line-height: 22px;
}

.summary-button {
  @extend .kayak-width;
}

.image {
  @extend .p-top-4,
  .p-bottom-4;
}
