@import 'imports';

.container {
  @extend .column,
  .m-top-2;
}

.primary-button {
  margin-bottom: 13px;
}

.primary-button-with-secondary-underlined {
  margin-bottom: 6px;
}
