@import 'imports';
$border-top: 20px;

.card-container {
  border-radius: 10px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  width: 93%;
}

.card-container-with-header  {
  border-top-left-radius: $border-top;
  border-top-right-radius: $border-top;
}

.header {
  @extend .full-width;

  background-color: $clear-hawkes-blue;
  border-radius: 20px;
  line-height: 20px;
  min-height: fit-content;
  padding: 5px 0;
}
