@import 'imports';

.container {
  @extend .column,
  .center;

  border-radius: 20px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  width: 70%;
}

.transition-card-title {
  @extend .oxford-blue,
  .medium,
  .text-center;

  font-size: 16px;
  line-height: 22px;
}

.transition-card-icon {
  padding-top: 10px;
}
