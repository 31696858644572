@import 'imports';

.buttons-container {
  @extend .column,
  .center,
  .m-bottom-3;
}

.main-button {
  @extend .white,
  .medium;

  background-color: $teal-blue;
  border-radius: 20px;
  height: 35px;
  padding: 0 20px;
  width: fit-content;
}

.manual-button {
  @extend .info-blue,
  .regular;

  width: fit-content;
}
