@import 'imports';

.container {
  margin: 0 25px;
}

.button {
  padding: 0 25px;
  width: fit-content;
}

.input {
  height: 75px;
}
