@import 'imports';

.logo-container {
  @extend .row,
  .middle;
}

.logo {
  @extend .m-left-1;

  height: 18px;
}
