@import 'imports';

.modal {
  max-height: 475px;
  height: auto;
}

.container {
  @extend .column,
  .full-width,
  .full-height,
  .space-between;

  padding: 30px;
}

.steps {
  @extend .m-bottom-2;

  & .step {
    @extend .row,
    .middle,
    .m-bottom-4;

    & .number-step {
      @extend .row,
      .center,
      .middle,
      .m-right-2;

      border: 2px dashed $dark-yellow;
      height: 36px;
      min-width: 36px;
      width: 36px;
      border-radius: 50px;

      font-size: 15px;
      font-weight: 700;
      color: $purple;
    }

    & .text-step {
      @extend .row,
      .middle;

      font-weight: 600;
      font-size: 14px;
      color: #17607B;
    }
  }
}

.logo {
  height: 20px;
  padding: 0 4px;
  margin-bottom: -4px;
}
