@import 'imports';

.container {
  border-radius: 10px;
  height: 40px;
  width: 100%;
}

.border {
  border-style: solid;
  border-width: 2px;
}

.disabled {
  opacity: 0.5;
}

.uppercase {
  text-transform: uppercase;
}

.primary {
  background-color: $purple;
  color: $white;
}

.secondary {
  background-color: $white;
  border-color: $purple;
  color: $purple;
}

.border-primary {
  border-color: $purple;
}

.border-secondary {
  border-color: $white;
}

.underline {
  margin: 5px 0;
  text-decoration: underline;
}

.info {
  @extend .regular;

  color: $info-blue;
}
