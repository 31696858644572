@import 'imports';

.content-container {
  @extend .column,
  .center,
  .middle,
  .full-width;
}

.buttons-contact-container {
  @extend .column,
  .center,
  .middle,
  .full-width;
}

.contacts {
  margin: 10px 0;
}
