@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height;
}

.stepper-bar {
  top: 85px;
}
