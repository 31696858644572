@import 'imports';

.documentation-info-container {
  @extend .row,
  .kayak-width,
  .space-between,
  .middle;
}

.documentation-info {
  @extend .row,
  .full-width,
  .middle;
}

.documentation-status-sign {
  @extend .row,
  .center,
  .m-right-2;
}

.documentation-info-text-container {
  @extend .column;
}

.documentation-info-text {
  @extend .regular;

  color: $grizzle-gray;
  font-size: 14px;
  line-height: 21px;
}

.documentation-info-error {
  @extend .regular;

  color: $error;
  font-size: 12px;
  line-height: 18px;
}

.upload-button {
  @extend .row,
  .center,
  .middle;

  background-color: $cosmic-cobalt;
  border-radius: 20px;
  color: $white;
  height: auto;
  padding: 3px 13px;
  width: fit-content;
}

.upload-button-text {
  @extend .medium,
  .m-right-1;
}

.edit-button {
  height: 35px;
  width: 37px;
}

.edit-icon {
  margin-left: 2px;
}
