@import 'imports';

.without-secondary-button {
  margin-bottom: 0;
}

.continue-back-buttons {
  @extend .kayak-width;

  margin-bottom: 5px;
}
