@import 'imports';

.perfect-button-container {
  @extend .column,
  .center,
  .m-bottom-1;
}

.manual-button {
  @extend .info-blue,
  .regular;

  width: fit-content;
}
