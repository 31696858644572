@import 'imports';

.modal {
  max-height: 260px;
  max-width: 300px;
  padding: 0;
}

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;

  padding: 25px;
}

.status-modal-title {
  @extend .medium;

  color: $teal-blue;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.status-modal-info {
  @extend .m-bottom-2;

  color: $oxford-blue;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
