@import 'imports';

.container-primary, .container-secondary {
  @extend .row,
  .middle;

  text-transform: capitalize;
  font-weight: 500;
  border-radius: 20px;
  padding: 4.5px 21px;
  height: fit-content;
  width: fit-content;
  text-align: center;

  font-size: 14px;
  line-height: 21px;
}

.container-primary {
  background-color: $cosmic-cobalt;
  color: $white;
  border: 1px solid transparent;
}

.container-secondary {
  background-color: transparent;
  color: $cosmic-cobalt;
  border: 1px solid $cosmic-cobalt;
}

.icon {
  @extend .m-left-1;
}

.label {
  line-height: 19px;
}
