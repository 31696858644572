@import 'imports';

.form-container {
  @extend .column,
  .full-width,
  .full-height,
  .middle,
  .center,
  .space-between;

  position: relative;
}

.container {
  @extend .column,
  .full-width,
  .full-height,
  .space-between,
  .center;

  padding: 20px;
}

.form-fields-container {
  height: fit-content;
}

.message-container {
  @extend .column,
  .full-width,
  .center;
}

.message {
  @extend .grizzle-gray;

  font-size: 11px;
  line-height: 16px;
  padding: 0 20px 10px;
  text-align: center;
}

.error-message {
  @extend .row,
  .full-width,
  .m-bottom-3;

  text-align: center;
}

.info-content {
  @extend .row,
  .middle,
  .end;

  position: relative;
  min-width: 20px;
  min-height: 20px;
  overflow: hidden;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;

  .info-button {
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    border-radius: 50px;
    fill: #733fe7;
    box-shadow: 0 4px 4px #e6e6e6;
  }

  .info-modal {
    background-color: #fff;
    border-radius: 6px 50px 50px 6px;
    box-shadow: 0 0 2px #051b5f;
    padding-inline: 5px 22px;
    margin-left: 5px;
    margin-block: 1px;
    font-size: 11px;
    line-height: 20px;
    cursor: pointer;
  }
}

.section-title {
  @extend .row,
  .space-between,
  .m-bottom-5,
  .middle;
}
