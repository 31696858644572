@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;

  min-height: fit-content;
}

.info-container {
  @extend .full-width;
}

.status-header {
  @extend .transition-header;

  @include xxxs {
    height: 65px;
  }
}

.info-header {
  @extend .m-bottom-3;
}

.status-page-title {
  @extend .semi-bold;

  font-size: 34px;
  @include xxxs {
    font-size: 26px;
  }
  line-height: 51px;
}

.credit-header {
  @extend .m-top-4;
}

.home-button {
  @extend .m-bottom-4,
  .kayak-width;

  @include xxxs {
    margin-bottom: 15px;
  }
}

.email-message-container {
  @extend .column,
  .full-width,
  .center,
  .middle,
  .m-bottom-2;
}

.documentation-button {
  @extend .kayak-width;

  margin: 10px 0 20px;
}

.share-link-button-container {
  @extend .m-bottom-3;
}

.share-link-button {
  @extend .kayak-width;
}
