@import 'variables/breakpoints';

@import 'variables/fonts';

@import 'animations';

@import 'layout';

@import 'marginsPaddings';

@import 'components';

@import 'variables/variables';

@import 'inputs';

@import 'fonts';

* {
  box-sizing: border-box;
  font-family: $poppins;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
a,
button,
select,
textarea {
  color: inherit;
  display: block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  white-space: pre-line;
}

input,
select,
textarea,
button {
  border: 0;
  border-radius: 0;
}

button {
  background: transparent;
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
}

.anchor {
  position: relative;
}

.select-label {
  @extend .small-text,
  .medium;

  align-items: center;
  background-color: $cosmic-cobalt;
  color: $white;
  display: flex;
  flex-direction: row;
  line-height: 21px;
  padding: 15px;
}

.pac-icon {
  display: none !important;
}

.pac-matched {
  display: inline-block !important;
}

.kayak-select {
  &__menu {
    z-index: 10;
  }

  &__control {
    &--is-disabled {
      background-color: $alto !important;
    }
  }
}
