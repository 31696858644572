.option {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  padding: 15px;
}
