@import 'imports';
$edit-button-size: 30px;

.edit-icon {
  fill: $white;
  margin-left: 3px;
}

.edit-button  {
  @extend .row,
  .center,
  .middle;

  background-color: $teal-blue;
  border-radius: 100px;
  box-shadow: 0 2px 2px rgba($black, 0.25);
  cursor: pointer;
  height: $edit-button-size;
  width: $edit-button-size;
}
