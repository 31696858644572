@import 'variables/colors';

.container {
  background-color: $teal-blue;
  margin-bottom: 20px;
  padding: 0 15px;
  width: fit-content;
}

.edit-button {
  fill: $white;
}
