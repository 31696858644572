@import 'imports';

.modal {
  height: fit-content;
  max-width: 360px;
  padding: 0;
}

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-around;

  padding: 25px 0 30px;
}

.titles-container {
  @extend .kayak-width,
  .m-bottom-1;

  text-align: center;
}

.blue-text {
  @extend .medium,
  .teal-blue;
}

.past-title {
  @extend .blue-text,
  .m-bottom-2;

  font-size: 16px;
  line-height: 22px;
}

.past-question {
  @extend .blue-text;

  font-size: 14px;
  line-height: 21px;
  margin-bottom: 3px;
}

.past-subtitle {
  @extend .regular,
  .charcoal;

  font-size: 12px;
  line-height: 18px;
}

.credit-header-container {
  @extend .m-bottom-1;

  min-height: 150px;
}

.buttons-container {
  @extend .kayak-width;
}

.picture {
  width: 80%;
}
