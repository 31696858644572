@import 'imports';

.container {
  @extend .column,
  .center,
  .middle,
  .full-width;

  min-height: fit-content;
}

.share-link-button {
  @extend .m-bottom-3;
}
