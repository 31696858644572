@import 'imports';

.certificate-card-container {
  @extend .full-width-card,
  .column,
  .center;

  padding: 20px 0;
}

.certificate-card-content-container {
  @extend .column,
  .kayak-width;
}

.certificate-title-container {
  @extend .row,
  .full-width,
  .middle,
  .m-bottom-3;
}

.certificate-title {
  @extend .teal-blue,
  .medium,
  .m-right-1;

  font-size: 16px;
  line-height: 13px;
}

.info {
  @extend .charcoal;

  @include xs {
    font-size: 14px;
  }
}

.buttons-container {
  @extend .column,
  .center,
  .m-bottom-3;
}

.main-button {
  @extend .white,
  .medium;

  background-color: $teal-blue;
  border-radius: 20px;
  height: 35px;
  padding: 0 20px;
  width: fit-content;
}

.manual-button {
  @extend .info-blue,
  .regular;

  width: fit-content;
}

.content {
  @extend .m-top-3;
}

.perfect-container {
  @extend .row;
}

.perfect-text {
  @extend .royal-blue,
  .m-left-2;

  font-size: 14px;
  font-style: italic;
}

.perfect-button-container {
  @extend .column,
  .center,
  .m-bottom-3;
}
