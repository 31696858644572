@import 'imports';

.container {
  @extend .full-width,
  .column,
  .center;
}

.content-container {
  @extend .row,
  .center,
  .full-width;

  padding: 15px 0;
}

.content-container-background {
  background-color: $selago;
}

.checkbox-container {
  @extend .kayak-width;
}
