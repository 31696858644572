@import 'imports';

.container {
  @extend .column,
  .full-width,
  .center;

  background-color: $ghost-white;
  min-height: fit-content;
  padding: 15px 20px;
}

.share-info {
  @extend .regular,
  .m-bottom-2,
  .oxford-blue;

  font-size: 14px;
  line-height: 20px;
}
