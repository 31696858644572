@import 'imports';

.container {
  @extend .full-width,
  .column,
  .full-height,
  .center,
  .space-between;
}

.buttons-container {
  @extend .column,
  .center,
  .middle,
  .kayak-width,
  .p-bottom-2;

  min-height: fit-content;
}

.buttons {
  @extend .column,
  .full-width;
}

.continue-back-buttons {
  @extend .buttons;

  margin-bottom: 10px;
}
