@import 'src/scss/variables/variables';

@import 'src/scss/variables/colors';
$transition-header-bottom-radius: 200px;
$error-size: 14px;

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.kayak-width {
  width: 85%;
}

.three-quarter-width {
  width: 75%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

@media screen and (max-width: $breakpoint-sm) {
  .full-width-mobile {
    width: 100%;
  }
}

.transition-header {
  @extend .column,
  .full-width,
  .center,
  .middle,
  .white;

  background-color: $purple;
  border-bottom-left-radius: $transition-header-bottom-radius;
  border-bottom-right-radius: $transition-header-bottom-radius;
  height: 100px;
}

.transition-header-title {
  @extend
  .bold,
  .text-center;

  font-size: 24px;
  line-height: 36px;
}

.transition-subtitle {
  @extend .row,
  .center,
  .medium,
  .charcoal;

  font-size: 14px;
  line-height: 21px;
  padding: 20px;
  text-align: center;
}

.documentation-subtitle {
  @extend .transition-subtitle,
  .full-width,
  .regular,
  .middle;

  background-color: $moon-raker;
  padding: 10px 0;
}

.full-width-card {
  @extend .full-width;

  border-radius: 6px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  padding: 20px;
}

.full-width-card-error {
  box-shadow: 0 0 15px $alizarin-crimson;
}

.error-sign {
  @extend .error-icon;

  height: $error-size;
  width: $error-size;
}

.text-pre-wrap {
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap;     /* Opera <7 */
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.section-title {
  @extend .row,
  .top,
  .full-width,
  .medium-title,
  .teal-blue,
  .medium,
  .m-bottom-2;

  margin-left: 2px;
}

.section-label {
  @extend .row,
  .top,
  .full-width,
  .medium-title,
  .teal-blue,
  .medium,
  .m-bottom-1;

  margin-left: 2px;
}

.main-title {
  @extend .row,
  .full-width,
  .center,
  .teal-blue,
  .regular;

  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.scraper-modal {
  @include xs {
    border: 0;
    border-radius: 0;
    margin: 0 auto;
    max-height: unset;
    max-width: unset;
    overflow: hidden;
    padding: 0;
    width: 100%;
  }
  max-height: 700px;
  max-width: 425px;
  padding: 0;
}

.error-icon {
  fill: $error;
}

.float-label {
  & .kayak-select {
    &__value-container {
      & .charcoal {
        padding-top: 30px;
      }
    }
  }
}

.kayak-select__menu {
  z-index: 10 !important;
}
