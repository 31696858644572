@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;
}

.inner-container {
  @extend .column,
  .full-width,
  .center;

  min-height: fit-content;
}

.content-container {
  @extend .kayak-width;
}

.buttons-container {
  @extend .inner-container;

  min-height: fit-content;
  padding: 10px 0;
}

.buttons-content-container {
  @extend .content-container,
  .m-bottom-2;
}

.documentation-card {
  @extend .m-bottom-4;
}
