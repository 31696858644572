@import 'imports';

.success-container {
  @extend .row;
}

.success-text {
  @extend .royal-blue,
  .m-left-2,
  .m-bottom-2;

  font-size: 14px;
  font-style: italic;
}
