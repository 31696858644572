@import 'imports';

.container {
  @extend .row,
  .full-width,
  .center,
  .middle;
}

.info-container {
  display: inline-block;
  text-align: center;
}
