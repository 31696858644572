@import 'imports';

.guarantor-info {
  @extend .medium;

  font-size: 16px;
  line-height: 22px;
  width: 65%;
}

.guarantor-button {
  display: inline-block;
  margin: 0;
  width: fit-content;
}
