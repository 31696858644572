@import 'imports';

.container {
  @extend .column,
  .full-width,
  .m-bottom-2;
}

.file-uploader-title {
  @extend .teal-blue,
  .medium;

  font-size: 16px;
  @include xxxs {
    font-size: 14px;
  }
  line-height: 22px;
  margin-left: 8px;
}

.file-uploader-title::first-letter {
  text-transform: capitalize;
}

.info-container {
  @extend .row,
  .full-width,
  .space-between;

  min-height: 140px;
}

.picture-container {
  @extend .column,
  .center,
  .middle;

  width: 40%;
}

.picture {
  height: 85%;
  width: 85%;
}

.items-container {
  @extend .column,
  .middle;

  width: 60%;
}
