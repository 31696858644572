@import 'imports';

$container-size: 20px;
$checkmark-size: $container-size/2;
$mouse-over-bg-color: $light-gray;
$checked-bg-color: $whisper;
$bg-color: $azure;
$checkmark-color: $white;

.container {
  @extend .row,
  .center,
  .middle;
  position: relative;
  cursor: pointer;
  height: 18px;
  padding-left: 20px;
  margin-bottom: 0px;
  width: fit-content;
  font-size: 14px;
  line-height: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  & .checkmark {
    @extend .row,
    .center,
    .middle;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 18px;
    background-color: transparent;
    border: 2px solid $purple;
    border-radius: 50px;
  }

  &:hover input~.checkmark {
    background-color: #F8F4FF;
  }

  & input:checked~.checkmark {
    &:after {
      display: block;
    }
  }

  & input:disabled~* {
    opacity: 0.40;
  }

  & .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    border-radius: 50px;
    width: 8px;
    height: 8px;
    background-color: $purple;
  }

  & .label {
    @extend .m-left-1;
  }
}
