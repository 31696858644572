@import 'imports';

.guide-message {
  @extend .grizzle-gray,
  .small-text,
  .text-center;

  line-height: 24px;
  margin: 20px 0;
}

.success-message-container {
  @extend .row,
  .full-width,
  .center,
  .middle;

  margin: 25px 0;
}

.success-text {
  margin-bottom: 0;
}
