@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;
}

.yellow-background {
  background-color: $light-yellow;
  height: 25px;
  padding: 2px;
}

.blue-background {
  background-color: $cosmic-cobalt;
  height: 25px;
  padding: 2px;
}
