@import 'imports';

.sections-card {
  @extend .full-width-card;

  padding: 15px 0;
}

.container {
  @extend .row,
  .full-width,
  .middle;
}

.owner-container {
  @extend .column,
  .center;

  width: 30%;
}

.owner-title {
  @extend .cosmic-cobalt,
  .bold;

  text-transform: uppercase;
}

.sections-rows {
  width: 70%;
}

.section-row {
  @extend .row,
  .full-width,
  .space-between,
  .middle;

  padding: 10px;
}

.section-row-background {
  background-color: $alice-blue;
}

.section-row-uncompleted {
  background-color: $cosmos;
}

.status-section-title {
  @extend .grizzle-gray;
}

.section-icon {
  @extend .m-right-2;
}

.section-title-with-icon {
  @extend .row,
  .middle;
}
