@import 'src/scss/variables/variables';

%appear {
  animation: appear $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%float {
  animation: float $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%grow {
  animation: grow $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

%slide-down {
  animation: slide-down $transition-duration $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
}

.slide-out-right {
  animation: slide-out-right 1.5s $transition-function;
  animation-fill-mode: both;
  animation-delay: #{--animation-delay};
  animation-iteration-count: 1;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes float {
  10% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  90% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out-right {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(1000px);
  }
}
