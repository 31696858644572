@import 'imports';

.guarantor-modal {
  max-height: 230px;
  max-width: 280px;
  padding: 0;
}

.guarantor-modal-container {
  @extend .column,
  .center;

  padding: 30px;
}

.guarantor-info-title {
  @extend .teal-blue,
  .medium;

  font-size: 16px;
  line-height: 22px;
  margin: 15px 0;
}

.guarantor-info-text {
  @extend .oxford-blue;

  font-size: 12px;
  line-height: 18px;
  padding: 0 10px;
  text-align: center;
}
