@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width;
}

.field-container {
  @extend .column,
  .kayak-width;

  margin: 10px 0;
}
