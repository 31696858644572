@import 'imports';
$logo-size: 15px;

.logo-container {
  @extend .column,
  .center,
  .middle;

  height: $logo-size;
  margin: 0 3px 0 6px;
  width: $logo-size;
}

.logo {
  height: $logo-size;
  width: $logo-size;
}

.message-info {
  color: $marshland;
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
}

.message-link {
  @extend .message-info,
  .middle;

  color: $info-blue;
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  text-decoration: underline;
}

.contact-button-container {
  display: inline-block;
  min-width: fit-content;
}

.migrante-contact-container {
  @extend .row,
  .center;
}
