@import 'imports';
$background-border-radius: 300px;

.container {
  @extend .column,
  .center,
  .full-width,
  .full-height,
  .space-between;
}

.background {
  @extend .column,
  .full-width,
  .center,
  .middle,
  .semi-bold,
  .teal-blue;

  background-color: $light-royal-blue;
  border-bottom-left-radius: $background-border-radius;
  border-bottom-right-radius: $background-border-radius;
  font-size: 34px;
  height: 55%;
  padding-bottom: 40px;
  text-align: center;
}

.error-title {
  @extend .bold,
  .m-bottom-2;

  color: $error;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.error-picture-container {
  margin-top: 8%;
  position: absolute;
}

.error-picture {
  width: 100%;
}

.error-container {
  @extend .column,
  .center,
  .full-width;
}

.error-subtitle {
  @extend .charcoal;

  font-size: 14px;
  line-height: 21px;
  text-align: center;
}

.error-buttons {
  @extend .kayak-width;

  margin-bottom: 40px;
}
