@import 'imports';

.container {
  @extend .column,
  .full-width,
  .center;
}

.logo {
  height: 30px;
}

.unique-key-subtitle {
  @extend .regular,
  .m-top-2;

  color: $charcoal;
  font-size: 14px;
  @include xxxs {
    font-size: 12px;
  }
  line-height: 18px;
  text-align: center;
}
