.kayak-select-label {
  z-index: 1;
  position: absolute;
  color: #737373;
  font-size: 10px;
  left: 16px;
  line-height: 21px;
  pointer-events: none;
  top: 4px;
}
