@import 'imports';
$border-radius: 100px;
$close-button-size: 30px;
$close-button-position: -15px;
$trasition-time: 200ms;

.container {
  @extend .column,
  .full-width,
  .end;
}

.close-button {
  @extend .row,
  .center,
  .middle;

  background-color: $light-red;
  border-radius: 100px;
  height: $close-button-size;
  left: $close-button-position;
  padding: 5px;
  position: absolute;
  top: $close-button-position;
  width: $close-button-size;
}

.button-container {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 0 6px $teal-blue;
  cursor: pointer;
  padding: 13px 16px;
  position: fixed;
  top: 40px;
}

.main-button-container {
  @extend .row,
  .middle;
}

.title-container {
  @extend .column,
  .middle;

  color: $grizzle-gray;
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
}

.logo-container {
  @extend .row,
  .middle;
}

.logo {
  @extend .m-left-1;

  max-height: 18px;
}

.go-button-container {
  @extend .row,
  .middle,
  .medium,
  .m-left-5;

  background-color: $teal-blue;
  border-radius: $border-radius;
  color: $white;
  font-size: 14px;
  padding: 3px 15px;
}

.arrow {
  @extend .m-left-1;
}

.hide-container {
  opacity: 0;
  transition: visibility 0s linear $trasition-time, opacity $trasition-time;
  visibility: hidden;
}
