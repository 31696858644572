.container {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.screens-container {
  max-width: 425px;
  width: 100%;
}
