@import 'imports';

.container {
  @extend .column,
  .center,
  .m-bottom-2;
}

.question {
  @extend .oxford-blue,
  .medium,
  .medium-title,
  .m-bottom-3;
}

.loading-container {
  @extend .kayak-width;

  height: 150px;
}

.radio-group-container {
  @extend .row,
  .space-around,
  .kayak-width,
  .m-bottom-3;
}

.credit-cash-message {
  @extend .column,
  .full-width,
  .center,
  .m-bottom-3;

  background-color: $clear-portage;
  color: $oxford-blue;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 20px;
  text-align: center;
}

.no-extra-cost {
  @extend .row,
  .middle,
  .center,
  .m-bottom-1;

  color: $marshland;
  fill: $marshland;
  font-size: 12px;
  text-align: center;

  & span {
    @extend .m-left-1;
  }
}
