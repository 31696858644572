@import 'imports';

.container {
  @extend .column,
  .center,
  .full-width;

  min-height: fit-content;
}

.label-with-select-container {
  @extend .p-bottom-2;
}
