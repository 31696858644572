@import 'imports';

.choice-card-container {
  @extend .full-width-card,
  .row,
  .middle,
  .space-between,
  .p-left-6,
  .p-right-6;

  cursor: pointer;
}

.choice-card-info {
  @extend .column;
}

.choice-title {
  @extend .medium;

  color: $cosmic-cobalt;
  font-size: 16px;
  line-height: 22px;
}

.choice-subtitle {
  @extend .choice-title,
  .p-right-1;
}

.image {
  @extend .p-right-1;

  width: 110px;
}

.subtitle-container {
  @extend .row,
  .middle;

  margin-top: 3px;
}
