@import 'imports';

.container {
  @extend .row,
  .middle,
  .full-width,
  .space-between;

  background-color: $light-purple;
  min-height: fit-content;
}

.back-arrow-container {
  @extend .row,
  .center,
  .middle;

  background-color: $white;
  border-radius: 100px;
  box-shadow: 0 2px 4px $light-black;
  cursor: pointer;
  margin: 7px 0 7px 20px;
  min-height: fit-content;
}

.back-arrow-container-disabled {
  opacity: 0.5;
}

.back-arrow {
  margin: 5px;
}

.guarantor-container {
  @extend .row,
  .full-height,
  .middle,
  .bold;

  background-color: $yellow;
  border-bottom-left-radius: 100px;
  padding-left: 25px;
}

.guarantor-icon-container {
  @extend .column,
  .full-height,
  .bottom;
}

.guarantor-icon {
  max-height: 44px;
}

.guarantor-title {
  color: $white;
  font-size: 14px;
  line-height: 16px;
  max-width: 100px;
}
