@import 'imports';

.container {
  @extend .column,
  .full-width,
  .center;
}

.share-info {
  @extend .regular,
  .m-bottom-2,
  .oxford-blue;

  font-size: 14px;
  line-height: 20px;
}

.share-button {
  @extend .medium,
  .white,
  .row,
  .center,
  .middle;

  background-color: $teal-blue;
  border-radius: 100px;
  font-size: 14px;
  height: 30px;
  @include xxxs {
    width: 80%;
  }
  width: 65%;
}

.share-button-text {
  margin-right: 5px;
}
