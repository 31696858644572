@import 'imports';
$logo-size: 15px;

.container {
  padding: 15px 10px 20px;
}

.transition-item {
  @extend .row;
}

.transition-label {
  @extend .grizzle-gray,
  .m-left-1;

  font-size: 14px;
  padding: 3px;
}

.logo {
  height: 13px;
  margin-top: 7px;
  width: 15px;
}
