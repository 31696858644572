@import 'imports';

.input-container {
  background: $white;
  border-color: $hawkes-blue;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  color: $dim-gray;
  position: relative;

  &:focus-within {
    border-color: $teal-blue;
  }
}

.input-container-success {
  border-color: $purple;
}

.input-container-error {
  border-color: $error;
}

.input-container label {
  color: $dim-gray;
  font-size: 14px;
  left: 14px;
  line-height: 21px;
  pointer-events: none;
  position: absolute;
  top: -10px;
  transform: translate(0, 20px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.7, 1) 0ms;
}

.input-container:focus-within label,
.input-container .input-label {
  color: $dim-gray;
  transform: translate(0,12px) scale(0.7);
}

.content-container {
  @extend .kayak-width;
}

.sign-container {
  width: 15%;
}

.input {
  box-sizing: border-box;
  color: $charcoal;
  font-size: 15px;
  line-height: 24px;
  margin: 14px 0 0 6px;
  width: 92%;
}

.input-without-label {
  margin: 7px 0 7px 14px;
}

.input-money {
  margin-left: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-disabled {
  background-color: $alto;
}

.disabled {
  background-color: #D3D3D3 !important;
}
