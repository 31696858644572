@import 'imports';

.guarantor-information-container {
  @extend .row,
  .center,
  .middle,
  .full-width;

  background-color: $sandwisp;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 3px;
}

.guarantor-needed {
  color: $oxford-blue;
  margin-right: 3px;
}

.guarantor-info {
  color: $info-blue;
  cursor: pointer;
  text-decoration: underline;
}

.guarantor-icon-container {
  @extend .column,
  .center,
  .middle;

  height: 60%;
  width: 10%;
}
