@import 'imports';

.modal {
  max-width: 350px;
  max-height: 275px;
  padding: 0;
}

.container {
  @extend .column,
  .space-between,
  .center,
  .full-height;

  padding: 20px;
}

$share-size: 60px;

.share {
  @extend .m-bottom-4;

  width: $share-size;
  height: $share-size;
}

.title {
  @extend .teal-blue,
  .medium,
  .m-bottom-2;

  font-size: 16px;
  line-height: 22px;
}

.subtitle {
  @extend .teal-blue;

  font-size: 12px;
  line-height: 18px;

  text-align: center;
}

.divider {
  @extend .full-width,
  .m-bottom-3,
  .m-top-3;

  height: 1px;

  background-color: $dove-gray;
}

.shareables-container {
  @extend .full-width,
  .row,
  .space-around,
  .top;
}

.shareable {
  @extend .column,
  .full-width,
  .middle,
  .center;
}

.icon {
  @extend .m-bottom-1;

  cursor: pointer;
}
